var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{class:[
    'moodboard-builder',
    {
      error: _vm.error,
    } ],attrs:{"data-step":_vm.STEP.PREVIEW}},[_c('MoodboardBuilderHeader'),_c('div',{staticClass:"mb-preview"},[_c('Canvas',{ref:"canvasRef",staticClass:"canvas",attrs:{"step":_vm.STEP.PREVIEW}}),_c('Panel',{staticClass:"mb-preview__panel"},[_c('div',{staticClass:"mb-preview__panel__content overview"},[(_vm.computedTitle)?_c('h1',{staticClass:"mbb-overview__title mb-xxs"},[_vm._v(" "+_vm._s(_vm.computedTitle)+" ")]):_vm._e(),(_vm.content.htmltext)?_c('div',{staticClass:"mbb-overview__lead mb-xs",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}}):_vm._e(),(_vm.content.restartCta)?_c('GAction',{staticClass:"overview__cta mb-xs",attrs:{"content":{
            label: _vm.content.restartCta.label,
            url: _vm.content.restartCta.url,
            modifiers: ['btn', 'outline', 'full-width'],
          }}}):_vm._e(),(_vm.content.quoteIncentiveHtmltext)?_c('div',{staticClass:"overview__text mb-xs",domProps:{"innerHTML":_vm._s(_vm.content.quoteIncentiveHtmltext)}}):_vm._e(),(_vm.quoteUrl && _vm.content.quoteCtaLabel)?_c('GAction',{staticClass:"overview__cta",attrs:{"content":{
            label: _vm.content.quoteCtaLabel,
            url: _vm.quoteUrl,
            icon: 'arrow',
            modifiers: ['btn', 'filled', 'full-width'],
          }}}):_vm._e()],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }