




























































import {
  ComputedRef,
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import type {
  MoodboardBuilderPreviewContent,
  MoodboardDefinition,
} from '@/inc/types'
import MoodboardBuilderHeader from '@/components/chrome/header/MoodboardBuilder.vue'
import Canvas from '@/components/moodboard-builder/Canvas.vue'
import Panel from '@/components/moodboard-builder/Panel.vue'
import { setMaterialAverageColor } from '@/inc/utils'
import { STEP } from '@/views/MoodboardBuilder.vue'
import { useGetters, useMutations } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'MoodboardBuilderPreview',
  components: {
    MoodboardBuilderHeader,
    Canvas,
    Panel,
  },
  setup() {
    const {
      SET_WALL_MAT: setWallMaterial,
      SET_FLOOR_MAT: setFloorMaterial,
      SET_PRIMARY_MAT: setPrimaryMaterial,
      SET_SECONDARY_MAT: setSecondaryMaterial,
      SET_TERTIARY_MAT: setTertiaryMaterial,
      SET_ACCESSORY_MAT: setAccessoryMaterial,
      SET_CONTACT_NAME: setContactName,
      SET_PROJECT_TYPE: setProjectType,
    } = useMutations('moodbuilder', [
      'SET_MOOD_TITLE',
      'SET_WALL_MAT',
      'SET_FLOOR_MAT',
      'SET_PRIMARY_MAT',
      'SET_SECONDARY_MAT',
      'SET_TERTIARY_MAT',
      'SET_ACCESSORY_MAT',
      'SET_CONTACT_NAME',
      'SET_PROJECT_TYPE',
    ])
    const { content, chrome } = useGetters(['content', 'chrome']) as {
      content: ComputedRef<MoodboardBuilderPreviewContent>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      chrome: ComputedRef<any>
    }
    const canvasRef = ref<InstanceType<typeof Canvas> | null>(null)
    const error = ref<null | { title: string; message: string }>(null)
    const definition = content.value.moodboard?.definition

    const setStoreFromDefinition = () => {
      if (moodboardDefinitionIsValid(definition)) {
        const {
          wall,
          floor,
          primary,
          secondary,
          tertiary,
          accessory,
          name,
          project,
        } = definition as MoodboardDefinition

        if (wall) {
          setWallMaterial(wall)
        }

        if (floor) {
          // Get average color, used for edges and text colors
          setMaterialAverageColor(floor)
          setFloorMaterial(floor)
        }

        if (primary) {
          // Get average color, used for text colors
          setMaterialAverageColor(primary)
          setPrimaryMaterial(primary)
        }

        if (secondary) {
          setSecondaryMaterial(secondary)
        }

        if (tertiary) {
          setTertiaryMaterial(tertiary)
        }

        if (accessory) {
          setAccessoryMaterial(accessory)
        }

        if (name) {
          setContactName(name)
        }

        if (project) {
          setProjectType({
            type: content.value.moodboard.projectType,
            label: project,
          })
        }
      }
    }

    // eslint-disable-next-line id-length
    const setBackgroundFromDefinition = () => {
      if (moodboardDefinitionIsValid(definition) && definition.background) {
        canvasRef.value?.setBackground(definition.background.src)
      }
    }

    // eslint-disable-next-line id-length
    const moodboardDefinitionIsValid = (definition?: MoodboardDefinition) => {
      if (definition && definition.version === 0) {
        return true
      }

      console.error('Could not parse moodboard definition', definition)

      return false
    }

    onBeforeMount(() => {
      setStoreFromDefinition()
    })

    onMounted(() => {
      setBackgroundFromDefinition()
    })

    // Build URL to quote form, appending moodboard query param to the end of url.
    const quoteUrl = computed(() => {
      if (chrome.value?.ctas?.headerRdv?.url) {
        if (content.value?.moodboard?.code) {
          return `${chrome.value.ctas.headerRdv.url}?moodboard=${content.value.moodboard.code}`
        }

        return `${chrome.value.ctas.headerRdv.url}`
      }

      return ''
    })

    const computedTitle = computed(() =>
      content.value.title.replace('#NAME#', definition?.name ?? '')
    )

    return {
      STEP,
      canvasRef,
      error,
      content,
      quoteUrl,
      computedTitle,
    }
  },
})
